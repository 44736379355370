import React, { useState } from 'react';
import ResumeForm from './EmailForm.jsx';
import ResumePreview from './EmailPreview.jsx';

const PersonalInfoForm = ({ personalInfo, updatePersonalInfo,handleBack, handleNext }) => {
  const [formData, setFormData] = useState(personalInfo);
  //console.log(personalInfo);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updatePersonalInfo(formData);
    handleNext();
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
      />
      <br />
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <br />
      <label htmlFor="location">Location:</label>
      <input
        type="location"
        name="location"
        value={formData.location}
        onChange={handleChange}
      />
      <br />
      <label htmlFor="phone">Phone:</label>
      <input
        type="tel"
        name="phone"
        value={formData.phone}
        onChange={handleChange}
      />
      <br />
      <label htmlFor="summary">Summary:</label>
      <input
        type="summary"
        name="summary"
        value={formData.summary}
        onChange={handleChange}
      />
      <br />
      <button type="button" onClick={handleBack}>
        Back
      </button>
      <button type="submit">Next</button>
    </form>
  );
};

export default PersonalInfoForm;
