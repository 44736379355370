
import React, { useState,useContext,useEffect } from 'react';
import axios from './api/axios.js';
import Cookies from 'js-cookie';
import { UserContext } from './context/UserContext.jsx';
import AuthContext from "./context/AuthProvider.js";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import storeActivity from './functions/storeActivity.jsx';

const RequestResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  const { auth,setAuth } = useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(0);
  const [loggedIn, setLoggedIn] = useState('');
  const [sessionId, setSessionId] = useState('');
  const [user_role_type, setUser_role_type] = useState('');


  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'LoginForm'             ,'Page'              ,'Landed on LoginForm page');
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('/request-reset-password', { email })
      .then(response => setMessage('Check your email for the reset link'))
      .catch(error => setMessage('Error sending reset email'));
  };

  return (
    <div className="container">
      <h2>Reset Password</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary">Send Reset Link</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default RequestResetPassword;

