import React, { useContext,useState,useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Cookies from 'js-cookie';
import LogoutButton from './LogoutButton.jsx';
import AuthContext from "./context/AuthProvider";
import ToolBar from './ToolBar.jsx';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import storeLocalCredits from './functions/storeLocalCredits.jsx';
import { retrieveLocalCredits } from './functions/retrieveLocalCredits';
import axios from './api/axios';
import "./Header.css";
import CareerGeniusLogo from './assets/CareerGeniusLogo.png'

const Header = () => {
const { auth,setAuth } = useContext(AuthContext);

const [username, setUsername] = useState('');

useEffect(() => {
setUsername(auth.username);
fetchData();
}, [auth.username]);


const [localCredits, setLocalCredits] = useState(0);


const [isSubscribed, setIsSubscribed] = useState(false);

useEffect(() => {         
  //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
  if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
    setIsSubscribed(true);
    }
}, []);

const fetchData = async () => {
  const tempCredits = await retrieveLocalCredits();
  if(tempCredits == -1){
    //create a new token to store local cedits
    storeLocalCredits(-1,0);
    
  }else{
    setLocalCredits(tempCredits);
  };
};

useEffect(() => { 
  axios.post('/get-credits', { sessionId: auth.sessionId }, {
    headers: {'Content-Type': 'application/x-www-form-urlencoded',},
     withCredentials: true // This sends the cookie with the request
  })
    .then(response => {
      // set the credit balance to the credits state
      setAuth({
        ...auth,
        credits: response.data.credits, // Update the credits field
      });
    })
    .catch(error => {
      //console.error(error);
    });
  

}, []);

const handleCopyClick = () => {
  
};

return (
<Navbar bg="light" expand="lg" style={{ width: "100%" }}>
<Navbar.Brand href="/">
  {/* <h3>Career Genius</h3> */}
   <img src={CareerGeniusLogo} alt="CareerGeniusLogo" className="CareerGeniusImg" to="/" /> 
</Navbar.Brand>
<Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse id="basic-navbar-nav">
<Nav className="mr-auto">
<ToolBar/>
</Nav>
{auth.loggedIn==1 ? (username && (
<Nav className="ml-auto">
<Navbar.Text style={{paddingRight:"20px"}}>Welcome: {username}</Navbar.Text>
{ isSubscribed ? (<Navbar.Text style={{paddingRight:"20px"}}><div style={{ color:'#257883' }}>
    Member Account
  </div></Navbar.Text>) : (auth.loggedIn==1 ?(localCredits>=1 ? <Navbar.Text style={{paddingRight:"20px"}}><div style={{ color:'#257883' }}>
    Account Credits: {auth.credits } + non account credits:{localCredits}
  </div></Navbar.Text> : <Navbar.Text style={{paddingRight:"20px"}}> <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div> </Navbar.Text> ) : <Navbar.Text style={{paddingRight:"20px"}}><div style={{ color:'#b73133' }}>
    Credits: {localCredits}
  </div></Navbar.Text> ) }
<LogoutButton />
</Nav>
)): <Nav className="ml-auto">
   <Link to="/loginform"  className="btn btn-outline-primary" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Login</Link> 

      <Link to="/signupform" className="btn btn-outline-secondary" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#333',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Signup</Link>  
</Nav>}



</Navbar.Collapse>
</Navbar>
);
};

export default Header;