import React, { useState } from 'react';
import PersonalInfoForm from './PersonalInfoForm';
import WorkExperienceForm from './WorkExperienceForm';
import EducationForm from './EducationForm';
import SkillsForm from './SkillsForm';
import ReviewForm from './ReviewForm';
import SocialPostPreview from './SocialPostPreview';
import CopyOrBuildSocialPost from './CopyOrBuildSocialPost';

const SocialPostForm = ({
  personalInfo ,
  updatePersonalInfo,
  addWorkExperience,
  addEducation,
  addSkills,
  currentStep,
  setCurrentStep,
  pasted,
  pastedSocialPost,
  setPastedSocialPost,
  setPasted
}) => {
  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const renderForm = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
          <h1 className="mx-auto text-center">Social Post Genius</h1>
          <br/>
          <CopyOrBuildSocialPost
            personalInfo={personalInfo}
            pastedSocialPost={pastedSocialPost}
            setCurrentStep={setCurrentStep}
            setPastedSocialPost={setPastedSocialPost}
            currentStep={currentStep}
            setPasted={setPasted}
          />
          </div>
        );
      case 2:
        return (
          <div>
          <h3>Personal Information - 1 of 5</h3>
          <br/>
          <PersonalInfoForm
            personalInfo={personalInfo}
            updatePersonalInfo={updatePersonalInfo}
            handleNext={handleNext}
            handleBack={handleBack}
          />
          </div>
        );
      case 3:
        return (<div>
          <h3>Employment History - 2 of 5</h3>
          <br/>
          <WorkExperienceForm
            addWorkExperience={addWorkExperience}
            handleNext={handleNext}
            handleBack={handleBack}
          />
          </div>
        );
      case 4:
        return (
          <div>
          <h3>Education History - 3 of 5</h3>
          <br/>
          <EducationForm
            addEducation={addEducation}
            handleNext={handleNext}
            handleBack={handleBack}
          />
          </div>
        );
      case 5:
        return (
          <div>
          <h3>Skills Step - 4 of 5</h3>
          <br/>
          <SkillsForm
            addSkills={addSkills}
            handleNext={handleNext}
            handleBack={handleBack}
          />
          </div>
        );
        case 6:
          return (
            <div>
          
            <ReviewForm
              handleBack={handleBack}
              pastedSocialPost={pastedSocialPost}
              pasted={pasted}
            />
            </div>
          );
      default:
        return null;

    }
  };

  return (
    <div>
      {renderForm()}

    </div>
  );
};

export default SocialPostForm;

