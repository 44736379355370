import axios from '../api/axios';
import React, { useState,useEffect,useContext } from 'react';
const uuid = require('uuid');

let clientIp = '0.0.0.0'; // Replace this with the actual client IP address
let country_code = '';
let country_name = '';
let city = '';
let postal = '';
let latitude = '';
let longitude = '';
let state =  '';

const config = {
  headers: {
    'X-Forwarded-For': clientIp,
  },
};

// Store the credits in the database with the token as the key
export const storeLocalCredits = async (token,credits) => {
const geoapi = process.env.geolocation_db_API_TOKEN;
  
  if(token = -1){ // if token does not exist create one
    // Generate a unique token
    token = uuid.v4();
  }
    try{
      //axios.get('https://api.ipify.org?format=json')
      axios.get('https://geolocation-db.com/json/'+geoapi)
      .then((response) => {
        clientIp = response.data.IPv4;
        country_code = response.data.country_code;
        country_name = response.data.country_name;
        city = response.data.city;
        postal = response.data.postal;
        latitude = response.data.latitude;
        longitude = response.data.longitude;
        state = response.data.state;
  try{
  const response = axios.post('/store-initial-local-credits', {
    token,
    credits,
    clientIp,
    country_code,
    country_name,
    city,postal,
    latitude,
    longitude,
    state
  });

  
  ////console.log(response);
  localStorage.setItem('localcreditstoken142023342', token); //madelocal storage name something random and unique so it wont clash with other
} catch (error) {
  //console.error(error);
  // Store the token in local storage
}

      })
      .catch((error) => {
        //console.error(error);
      });
    }  catch (error) {
      //console.error(error);
      try{
        const response = axios.post('/store-initial-local-credits', {
          token,
          credits,
          clientIp
        });
        ////console.log(response);
        localStorage.setItem('localcreditstoken142023342', token); //madelocal storage name something random and unique so it wont clash with other
      } catch (error) {
        //console.error(error);
        // Store the token in local storage
      }
    }

};

export default storeLocalCredits;

