import React, { useState,useContext,useEffect } from 'react';
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import "./About.css";
import storeActivity from './functions/storeActivity.jsx';

const About = () => {
  const { auth,setAuth } = useContext(AuthContext);

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'About'             ,'Page'              ,'Landed on About page');
  }, []);
  
  return(
  <div style={{ textAlign: 'center' ,padding: '20px',}}>
    <h2>About</h2>
    <p>Welcome to our cover letter generator!</p>
    <p>We know that writing cover letters can be time-consuming and overwhelming, especially when you're applying for multiple jobs. That's where we come in.</p>
    <p>Our Genius generator uses advanced artificial intelligence technology to generate personalized cover letters for you. Simply enter your resume or cover letter, the job position you're applying for, and any qualifications or requirements for the job. Our AI will then generate a unique cover letter for you, tailored to the job and your qualifications.</p>
    <p>Not only does our cover letter generator save you time, it also helps you stand out from the competition. Our AI is trained on a diverse range of cover letters, so it can generate a wide range of styles and tones to suit your needs.</p>
    <p>Try our cover letter generator today and take the first step towards landing your dream job!</p>

  </div>
  )

  };

export default About; 
 