import React, {useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import axios from '../api/axios';
import AutoLogout from '../functions/AutoLogout';
import AuthContext from "../context/AuthProvider";
import storeActivity from '../functions/storeActivity.jsx';

function AuthenticatedComponent() {
    const navigate = useNavigate(); // Use useNavigate instead of useHistory
    const [hasCheckedAuth, setHasCheckedAuth] = useState(false);
    const { auth, setAuth } = useContext(AuthContext);

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                // Passing sessionId from auth in the request body
                const response = await axios.post('/protected-route', { 
                    sessionId: auth.sessionId 
                }, {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    withCredentials: true // This sends the cookie with the request
                });

                if (response.status === 200) {
                    
                }else{console.log("User is other authenticated.");}
            } catch (error) {
                if (error.response && (error.response.status === 401 ||error.response.status === 500) ) {
                    
                    console.log("User is not authenticated code: .",error.response.status);

                    
                        // Store activity before clearing session
                       // storeActivity(auth.sessionId, "Page Landing", 'AutoLogout', 'Page', 'User was auto-logged out');
            
                        // Clear local storage and state

                        
                        //setAuth({ username: 'null', credits: 0, loggedIn: '0', sessionId: '', email: '' });
            
                  
                            // Redirect the user to the home page
                        
                    localStorage.setItem('username', null);
                    localStorage.setItem('credits', 0);
                    localStorage.setItem('loggedIn', 0);
                    localStorage.setItem('sessionId', '');
                    localStorage.setItem('auth-token', '');
                    localStorage.setItem('email', '');
                    localStorage.setItem('user_role_type', '');
                    localStorage.removeItem('');
                    localStorage.removeItem('username');
                    localStorage.removeItem('credits');
                    localStorage.removeItem('loggedIn');
                    localStorage.removeItem('auth-token');
                    localStorage.removeItem('sessionId');
                    localStorage.removeItem('email');
                    localStorage.removeItem('user_role_type');
                    localStorage.removeItem('isVerified');
                    localStorage.clear();
                    setAuth({username:'null',credits:0,loggedIn:'0',sessionId:'',email:''});
                    alert("You have been logged out due to inactivity or session expiry.");
                    window.location.replace("/");
                        
                    
                    
                    //navigate('/login'); // Use navigate to redirect to login page
                   // window.location.replace("/");
                }
            } finally {
                setHasCheckedAuth(true); // Ensure the check only happens once
            }
        };

        if (!hasCheckedAuth) {
            checkAuthentication(); 
        }
    }, [navigate, hasCheckedAuth]);

    return (
        <div>
            {/* Your component content */}
           {/*  <AutoLogout />*/} 
        </div>
    );
}

export default AuthenticatedComponent;
