import React, { useEffect, useContext } from 'react';
import { Route, Link, BrowserRouter as Router } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
//import './BestPractices.css';
import { Container } from 'react-bootstrap';


export const BestPracticesForResumeAndCoverLetterWriting = () => {
const { auth } = useContext(AuthContext);

useEffect(() => {
storeActivity(auth.sessionId, 'Page Landing', 'Best Practices for Resume and Cover Letter Writing', 'Page', 'Landed on Best Practices page');
}, []);

return (
<Container>

<div className="best-practices-container text-center">
<h1 className="mb-4">Discover the Best Practices for Resume and Cover Letter Writing at CareerGenius.org</h1>
<p className="mb-5">
Are you looking for the best practices for crafting an outstanding resume and cover letter? Look no further! At CareerGenius.org, our team of professional resume writers are here to share their expertise and help you write the best resume and cover letter possible. 
</p>
<h2 className="mb-4">What You'll Learn About at CareerGenius.org</h2>
<ul className="mb-5">
<li>How to optimize your resume for your target job</li>
<li>The most effective techniques for writing a winning cover letter</li>
<li>Insider tips from experienced professional resume writers</li>
<li>Guidance on presenting your best self to potential employers</li>
</ul>
<h2 className="mb-4">Get the Best Resume and Cover Letter Writing Tips at CareerGenius.org Today!</h2>
<p className="mb-5">
Ready to start writing your best resume and cover letter yet? Visit CareerGenius.org and take advantage of our expertise and resources. We're here to help you every step of the way!
</p>
<Link to="/" className="btn btn-primary">
Home
</Link>
</div>
</Container>
);
};

export default BestPracticesForResumeAndCoverLetterWriting;