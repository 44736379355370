import React, { useState,useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import { incrementLocalCredits as increment } from '../functions/incrementLocalCredits.jsx';
import { Container } from 'react-bootstrap';
import Contact from '../Contact';
import "./AIBuilderCards.css";
import storeActivity from '../functions/storeActivity.jsx';
import CareerGeniusLogo from '../assets/CareerGeniusLogo.png'

import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';


export const AIBuilderCards = () => {

    const { auth,setAuth } = useContext(AuthContext);


  return (
    <div className='AIBuilderCards'>
        {/* <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
            <Grid item>
        <Paper
          sx={{
            height: 200,
            width: 900,
            padding: "30px",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
            borderRadius: 10,
            transition: 'all 0.2s ease-in-out',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        > 
          <h2>Welcome to our AI Writing Tools!</h2>
      <p>We know that writing cover letters, resumes, emails, and social media posts can be time-consuming and overwhelming, especially when you're applying for multiple jobs or trying to reach out to potential clients. That's where we come in.</p>
      
      <h3>Select an AI Writing Tool</h3>
        </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    {auth.loggedIn==1 ? 
        <div className="d-flex justify-content-center">
        <div style={{ maxWidth: '100%' }} >
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
            <Grid item>
        <Paper
          sx={{
            height: 300,
            width: 300,
            padding: "20px",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
            borderRadius: 10,
            transition: 'all 0.2s ease-in-out',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        > 
          <Typography level="h3" fontSize="xl"  fontWeight="bold" sx={{ mb: 0.5, mt: 1.5 }}>
            Cover Letter Genius
          </Typography>
          <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
            <li className="mx-auto text-left">Generates customized cover letters</li>
            <li className="mx-auto text-left">Uses AI technology to analyze job descriptions</li>
            <li className="mx-auto text-left">Provides a well-rounded image to potential employers</li>
          </ul>
          <Link to="/CoverLetterBuilder"  style={{ margin: '20px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
          {/* <img src={coverLetterImage} alt="Cover Letter AI" sx={{ mt: 1.5, mb: 1.5 }} /> */}
        </Paper>
            </Grid>
              <Grid item>
              <Paper
                        sx={{
                            height: 300,
                            width: 300,
                            padding: "20px",
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                            borderRadius: 10,
                            transition: 'all 0.2s ease-in-out',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                        > 
                      <Typography level="h3" fontSize="xl" fontWeight="bold"  sx={{ mb: 0.5, mt: 1.5 }}>
                        Resume Genius
                    </Typography>
                    <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
                        <li className="mx-auto text-left">Generates customized resumes</li>
                        <li className="mx-auto text-left">Uses AI technology to analyze job descriptions</li>
                        <li className="mx-auto text-left">Optimizes resumes to get past <span className="stickout">ATS filters</span></li>
                    </ul>
                  <Link to="/ResumeTools"  style={{ margin: '20px' }} className="btn btn-success mr-2 same-size-btn">Resume AI Tools</Link>
                  {/* <img src={resumeImage} alt="Resume AI" sx={{ mt: 1.5, mb: 1.5 }} /> */}
              </Paper>
              </Grid>
              <Grid item>
              <Paper
                        sx={{
                            height: 300,
                            width: 300,
                            padding: "20px",
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                            borderRadius: 10,
                            transition: 'all 0.2s ease-in-out',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                        > 
                   <Typography level="h3" fontSize="xl" fontWeight="bold"  sx={{ mb: 0.5, mt: 1.5 }}>
                  Thank You Email Genius
                  </Typography>
                  <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
                    <li className="mx-auto text-left" >Auto-generates personalized thank you emails</li>
                    {/* <li className="mx-auto text-left">Complements your job application and makes a great impression</li> */}
                    <li className="mx-auto text-left">Optimized for readability and effectiveness</li>
                  </ul>
                  <Link to="/EmailBuilder"  style={{ margin: '20px' }} className="btn btn-success mr-2 same-size-btn">Thank You Email AI</Link>
                  {/* <img src={thankYouEmailImage} alt="Thank You Email AI" /> */}
              </Paper>
          </Grid>
          <Grid item>
          <Paper
            sx={{
              height: 300,
              width: 300,
              padding: "20px",
              backgroundColor: (theme) =>
                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
              borderRadius: 10,
              transition: 'all 0.2s ease-in-out',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          > 
            <Typography level="h3" fontSize="xl" fontWeight="bold" sx={{ mb: 0.5, mt: 1.5 }}>
              AI Interview Prep
            </Typography>
            <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
              <li className="mx-auto text-left">Generates interview questions for you to answer</li>
              <li className="mx-auto text-left">Provides real-time feedback on your answers</li>
              <li className="mx-auto text-left">Helps you prepare and improve your interview skills</li>
            </ul>
            <Link to="/InterviewPrep" style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">
              AI Interview Prep
            </Link>
          </Paper>
        </Grid>


            
          </Grid>
        </Grid>
        </Grid>
        </div>
        </div> 
        : 
        
        <div className="d-flex justify-content-center">
        <div style={{ maxWidth: '100%' }} >
        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
            <Grid item>
        <Paper
          sx={{
            height: 300,
            width: 300,
            padding: "20px",
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
            borderRadius: 10,
            transition: 'all 0.2s ease-in-out',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        > 
           <Typography level="h3" fontSize="xl" fontWeight="bold"  sx={{ mb: 0.5, mt: 1.5 }}>
            Cover Letter Genius
          </Typography>
          <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
            <li className="mx-auto text-left">Generates customized cover letters</li>
            <li className="mx-auto text-left">Uses AI technology to analyze job descriptions</li>
            <li className="mx-auto text-left">Provides a well-rounded image to potential employers</li>
          </ul>
          <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
          {/* <img src={coverLetterImage} alt="Cover Letter AI" sx={{ mt: 1.5, mb: 1.5 }} /> */}
        </Paper>
            </Grid>
              <Grid item>
              <Paper
                        sx={{
                            height: 300,
                            width: 300,
                            padding: "20px",
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                            borderRadius: 10,
                            transition: 'all 0.2s ease-in-out',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                        > 
                       <Typography level="h3" fontSize="xl" fontWeight="bold"  sx={{ mb: 0.5, mt: 1.5 }}>
                        Resume Genius
                    </Typography>
                    <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
                        <li className="mx-auto text-left">Generates customized resumes</li>
                        <li className="mx-auto text-left">Uses AI technology to analyze job descriptions</li>
                        <li className="mx-auto text-left">Optimizes resumes to get past <span className="stickout">ATS filters</span></li>
                    </ul>
                  <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Resume AI</Link>
                  {/* <img src={resumeImage} alt="Resume AI" sx={{ mt: 1.5, mb: 1.5 }} /> */}
              </Paper>
              </Grid>
              <Grid item>
              <Paper
                        sx={{
                            height: 300,
                            width: 300,
                            padding: "20px",
                            backgroundColor: (theme) =>
                            theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                            boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
                            borderRadius: 10,
                            transition: 'all 0.2s ease-in-out',
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}
                        > 
                  <Typography level="h3" fontSize="xl" fontWeight="bold"  sx={{ mb: 0.5, mt: 1.5 }}>
                  Thank You Email Genius
                  </Typography>
                  <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
                    <li className="mx-auto text-left" >Auto-generates personalized thank you emails</li>
                    <li className="mx-auto text-left">Complements your job application and makes a great impression</li>
                    <li className="mx-auto text-left">Optimized for readability and effectiveness</li>
                  </ul>
                  <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Thank You Email AI</Link>
                  {/* <img src={thankYouEmailImage} alt="Thank You Email AI" /> */}
              </Paper>
          </Grid>
          <Grid item>
  <Paper
    sx={{
      height: 300,
      width: 300,
      padding: "20px",
      backgroundColor: (theme) =>
        theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      boxShadow: '0px 0px 20px rgba(0,0,0,0.2)',
      borderRadius: 10,
      transition: 'all 0.2s ease-in-out',
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between"
    }}
  > 
    <Typography level="h3" fontSize="xl" fontWeight="bold" sx={{ mb: 0.5, mt: 1.5 }}>
      AI Interview Prep
    </Typography>
    <ul sx={{ listStyle: "disc", ml: 2, mt: 0.5, fontSize: "sm" }}>
      <li className="mx-auto text-left">Generates interview questions for you to answer</li>
      <li className="mx-auto text-left">Provides real-time feedback on your answers</li>
      <li className="mx-auto text-left">Helps you prepare and improve your interview skills</li>
    </ul>
    <Link to="/InterviewPrep" style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">
      AI Interview Prep
    </Link>
  </Paper>
</Grid>

            
          </Grid>
        </Grid>
        </Grid>
        </div>
        </div>
        }


        {/* {auth.loggedIn==1 ? 
      <div className="d-flex justify-content-center">
      <div style={{ maxWidth: '90%' }} >
        <Link to="/CoverLetterBuilder"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
        <Link to="/ResumeBuilder"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Resume AI</Link>
        <Link to="/EmailBuilder"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Thank You Email AI</Link>
        <Link to="/SocialPostBuilder"   style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Social Post AI</Link>
      </div>
      </div> : <div className="d-flex justify-content-center">
      <div style={{ maxWidth: '90%' }} >
        <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
        <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Resume AI</Link>
        <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Thank You Email AI</Link>
        <Link to="/loginform"   style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Social Post AI</Link>
      </div>
      </div> } */}

    </div>

  );

                };

export default AIBuilderCards