import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
//import './ResumeHelp.css';
import { Container } from 'react-bootstrap';
export const ResumeHelp = () => {
const { auth } = useContext(AuthContext);

useEffect(() => {
storeActivity(auth.sessionId, 'Page Landing', 'Resume Help', 'Page', 'Landed on Resume Help page');
}, []);

return (
    <Container>
<div className="resume-help-container text-center">

<h1 className="mb-4">Get the Best Resume Help at CareerGenius.org</h1>
<p className="mb-5">
Are you looking for resume help to land your dream job? Look no further! CareerGenius.org offers expert resume writing services to help you stand out from the competition. Our team of experienced writers will craft a customized resume tailored to the specific job you're applying for, using AI technology to optimize it for your target job.
</p>
<div>
      <p>
        Whether you're looking to make a career change, or simply want to improve your chances of landing your dream job, having a strong and effective resume is essential.
      </p>
      <p>
        But, with so many tips and tricks out there for crafting the perfect resume, it can be difficult to know where to start. That's why I want to share some insights and advice for getting the most out of your resume and standing out from the competition.
      </p>
      <p>
        First and foremost, it's important to remember that your resume is a marketing tool. Just like any other product, your resume needs to sell you and your qualifications to potential employers. So, you need to make sure that it is tailored to the specific needs and requirements of the job you're applying for.
      </p>
      <p>
        One of the most effective ways to do this is to use keywords that match the job description and requirements. Many companies use applicant tracking systems (ATS) to screen resumes, and having the right keywords can help your resume make it past the ATS and get noticed by hiring managers.
      </p>
      <p>
        Another key aspect of a strong resume is to focus on your achievements, not just your responsibilities. Instead of simply listing what you did in each role, use quantifiable data and concrete examples to show how you made a difference and impacted your previous positions.
      </p>
      <p>
        Additionally, your resume should be visually appealing and well-formatted, with a clean and modern layout that is easy to read. Use simple yet effective design elements like bold text and bullet points to make your resume stand out, and choose a font and color scheme that is professional and easy to read.
      </p>
      <p>
        Finally, it's important to proofread your resume thoroughly for typos, grammar errors, and formatting issues. These small mistakes can detract from the overall impact of your resume and give a poor impression to hiring managers.
      </p>
      <p>
        By following these tips, you can create a strong and effective resume that showcases your achievements, skills, and qualifications in a clear and impactful way. Whether you're looking to make a career change, or simply want to improve your chances of landing your dream job, a well-crafted resume is an essential tool that can help you stand out from the competition and make a lasting impression on potential employers.
      </p>
    </div>
<h2 className="mb-4">Why Choose CareerGenius.org for Your Resume Help?</h2>
<ul className="mb-5">
<li>AI-powered technology for optimized resumes</li>
<li>Customized templates to fit your unique needs and job target</li>
<li>Affordable pricing options to meet your budget</li>
<li>Dedicated customer support for all of your questions and concerns</li>
</ul>
<h2 className="mb-4">Get the Best Resume Help at CareerGenius.org Today!</h2>
<p className="mb-5">
Ready to take the first step towards your dream job? Fill out our quick and easy online form at CareerGenius.org and one of our expert resume writers will be in touch to help you create the best resume for your job search.
</p>

<Link to="/" className="btn btn-primary">
Home
</Link>
</div>
</Container>
);
};

export default ResumeHelp;