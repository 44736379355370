import React, { useState,useEffect,useContext } from 'react';

import { Link, Route } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import { incrementLocalCredits as increment } from './functions/incrementLocalCredits.jsx';
import { Container } from 'react-bootstrap';
import Contact from './Contact';
import "./ResumeTools.css";
import storeActivity from './functions/storeActivity.jsx';
import CareerGeniusLogo from './assets/CareerGeniusLogo.png'
import AIBuilderCardsResume from './components/resumeAITools/AIBuilderCardsResume.jsx'
import AIBuilderCardsMobileResume from './components/resumeAITools/AIBuilderCardsMobileResume.jsx'
import useWindowDimensions from './functions/useWindowDimensions.jsx'

import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';

import ApplePay from './ApplePay';


const ResumeTools = () => {
    const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY
    const { auth,setAuth } = useContext(AuthContext);
    const [localCredits, setLocalCredits] = useState(0);
    const { height, width } = useWindowDimensions();

    const incrementLocalCredits = async () => {
        const creditsToken = localStorage.getItem('localcreditstoken142023342');
      const result = increment(creditsToken);
      if(result != 1){
      setLocalCredits(localCredits + 1);
      }
      //window.location.replace("/");
      };

      useEffect(() => {         
        //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
        storeActivity( auth.sessionId       ,"Page Landing"     ,'ResumeTools'             ,'Page'              ,'Landed on ResumeTools page');
      }, []);

      
     

  return (
  
    <div style={{ textAlign: 'center',
     padding: '20px',
     marginLeft: width > 700 ? '20%' : '0',
     marginRight: width > 700 ? '20%' : '0' }}>
     

      <h1> <img src={CareerGeniusLogo} alt="CareerGeniusLogo" className="careerGeniusLogoImg"  /></h1>
      


      <br/>
      {width >= 768 ? 
      <div>
      <h2>Revolutionize your Job Search with AI-Powered Resume Tools!</h2>
      <p>We understand that crafting the perfect resume can be a daunting task. That's why we offer a suite of AI-powered tools designed to help you create customized, professional-quality resumes that will help you stand out from the competition.</p>
      </div>
      :null }
      <h3>Choose Your AI Resume Writing Tool</h3>
      {width >= 768 ? <AIBuilderCardsResume /> : <AIBuilderCardsMobileResume/> }
      

      <br/>
      {width >= 768 ? 
      <div>
      <p>Our AI-powered resume writing tools use state-of-the-art artificial intelligence to generate personalized documents for you. Simply enter the required information and our AI will generate a unique resume for you, tailored to your skills and experience.</p>
<p>Not only do our AI-powered tools save you time, but they also help you showcase your talents and abilities in a way that will grab the attention of potential employers. Our AI is trained on a wide range of resumes, so it can generate a variety of styles and formats to suit your needs.</p>      {/* <p>To get started, choose one of the options:</p> */}
</div>
      :null }
      <br/>
      <br/>
      
      <br/>
      <br/>

     

   
    </div>

   
  );
}

export default ResumeTools;
