import React from "react";

const Privacy = () => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Privacy Policy</h1>
      <p>
        At Career Genius, your privacy is our priority. This Privacy Policy
        explains how we collect, use, and protect your information.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect the following information when you use our app or services:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Name, email address, etc.
        </li>
        <li>
          <strong>Device Information:</strong> IP address, device type, browser
          type, etc.
        </li>
        <li>
          <strong>Usage Data:</strong> Analytics, app interactions, crash
          reports, etc.
        </li>
        <li>
          <strong>Location Data:</strong> If enabled, your approximate location.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use your information for the following purposes:</p>
      <ul>
        <li>To provide and improve our services.</li>
        <li>To personalize your user experience.</li>
        <li>To communicate with you regarding updates or support.</li>
        <li>To analyze app performance and usage trends.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell or share your personal information with third parties,
        except in the following cases:
      </p>
      <ul>
        <li>
          To comply with legal obligations or protect our rights and property.
        </li>
        <li>
          With trusted service providers who assist in app functionality,
          analytics, or communication.
        </li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We use industry-standard security measures to protect your information.
        However, no online platform is 100% secure, so we encourage caution
        while sharing personal data.
      </p>

      <h2>5. Your Rights</h2>
      <p>
        You have the following rights regarding your data:
      </p>
      <ul>
        <li>Request access to the information we have about you.</li>
        <li>Request deletion of your data.</li>
        <li>Opt-out of data collection for analytics or marketing.</li>
      </ul>

      <h2>6. Third-Party Services</h2>
      <p>
        Our app may use third-party tools (e.g., Google Analytics). These
        services may collect and process data under their respective privacy
        policies.
      </p>

      <h2>7. Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will
        be reflected on this page, and significant updates will be communicated
        to you.
      </p>
    </div>
  );
};

export default Privacy;
