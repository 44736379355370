import axios from '../api/axios';
import React, { useState,useEffect,useContext } from 'react';
// Retrieve the token from local storage
//const token = localStorage.getItem('token');//
// Use the token to retrieve the credits from the database
export const retrieveLocalCredits = async () => {
  try {
    const token = localStorage.getItem('localcreditstoken142023342');
    ////console.log("token:" + token);
    const response = await axios.post('/get-local-credits', {
      token,
    },{
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
         withCredentials: true // This sends the cookie with the request
      });
    ////console.log(response);
    return response.data.credits;
  } catch (error) {
    //console.error(error);
    return 0;
  }
  return 0;
};

export default retrieveLocalCredits;
//retrieveCredits(token);