import React,{useState,useEffect,useContext} from 'react';
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import PersonalInfo from './PersonalInfo';
import WorkExperience from './WorkExperience';
import Education from './Education';
import Skills from './Skills';
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import emailjs from 'emailjs-com';
import storeLocalCredits from '../functions/storeLocalCredits.jsx';
import { retrieveLocalCredits } from '../functions/retrieveLocalCredits';
import { incrementLocalCredits as increment } from '../functions/incrementLocalCredits.jsx';
import { decrementLocalCredits as decrement } from '../functions/decrementLocalCredits.jsx';
import { Container } from 'react-bootstrap';
import "./SocialPostPreview.css";
import { FaSpinner } from 'react-icons/fa';
import storeActivity from '../functions/storeActivity.jsx';
import {MyAlert,MyAlertMessage,MyAlertDocument} from '../components/MyAlert.jsx';

const ChatGPTAPIKey = process.env.REACT_APP_GPT_API_KEY;
const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY
const emailjsAPIKEY= process.env.REACT_APP_EMAILJS_API_KEY

const BrainGeniusAPIKey = process.env.REACT_APP_GENIUS_BRAIN_API_KEY;

const { Configuration, OpenAIApi } = require("openai");
const configuration = new Configuration({
  apiKey: ChatGPTAPIKey,
});

const openai = new OpenAIApi(configuration);

const SocialPostPreview = ({
  personalInfo,
  workExperience,
  education,
  skills,
  pasted,
  pastedSocialPost,
}) => {


  
  
  const { auth,setAuth } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [socialPostText, setSocialPostText] = useState('');
  const [localCredits, setLocalCredits] = useState(0);
  const [sessionId, setSessionId] = useState('');
  const [loggedIn, setLoggedIn] = useState('');
  const [email, setEmail] = useState('');
  const [credits, setCredits] = useState(0);
  const [aISocialPost, setAISocialPost] = useState('');

  const [triggerBuyButtonAlert, setTriggerBuyButtonAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);


  const [documentOpen, setDocumentOpen] = useState(false);
  const handleDocumentOpenModal = () => setDocumentOpen(true);
  const handleDocumentCloseModal = () => setDocumentOpen(false);



  const [socialMediaPlatform, setSocialMediaPlatform] = useState('');
  const [intent, setIntent] = useState('');
  const [tone, setTone] = useState('');

 
  const [username, setUsername] = useState('');
  
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'SocialPostPreview'             ,'Page'              ,'Landed on SocialPostPreview page');
  }, []);


  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    if(auth.user_role_type == "BASIC PLAN" || auth.user_role_type == "ADVANTAGE PLAN" || auth.user_role_type == "ELITE PLAN"  ){
      setIsSubscribed(true);
      }
  }, []);

  useEffect(() => { 
    setUsername(localStorage.getItem('username'));
    setCredits(localStorage.getItem('credits'));
    setLoggedIn(localStorage.getItem('loggedIn'));
    setSessionId(localStorage.getItem('sessionId'));
    setEmail(localStorage.getItem('email'));
    setEmailAddress(auth.email);
 
  }, []);

  const decrementLocalCredits = async () => {
    const creditsToken = localStorage.getItem('localcreditstoken142023342');
  const result = decrement(creditsToken);
  if(result != 1){
  setLocalCredits(localCredits -1);
  }
  //window.location.replace("/");
  };

  const fetchData = async () => {
    const tempCredits = await retrieveLocalCredits();
    if(tempCredits == -1){
      //create a new token to store local cedits
      storeLocalCredits(-1,0);
      
    }else{
      setLocalCredits(tempCredits);
    };
  };

  const sendEmail = async (email, coverLetter) => {
    try {
      await emailjs.send(
        "service_j8ecen9",
        "template_1uenfe9",
        {
          to: email,
          subject: 'Generated Social Media Post',
          message: coverLetter,
        },
        emailjsAPIKEY
      );
    } catch (error) {
      console.error(error);
    }
  };


  const sendEmailServerSide = async (email, coverLetter,emailType,documentType) => {
    try {
      await axios.post('/send-email', {sessionId:auth.sessionId,email:email,message:coverLetter,emailType:emailType,documentType:documentType},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
    } catch (error) {
      //console.error(error);
    }
  };

  function stripSQLInjection(text) {
    text = String(text);
    return text.replace(/'/g, "&apos;")
    .replace(/"/g, "&quot;")
    .replace(/;/g, "&semi;")
    .replace(/-/g, "&dash;")
    .replace(/\+/g, "&plus;");
  }

  const sendSocialPostTextToServer = (socialPostText) => {
    const endpoint = '/api/email'; // Replace with the URL of your endpoint
    const data = { socialPostText };
  
    axios.post(endpoint, data)
      .then((response) => {
        // The text for the email was successfully saved to the server
        ////console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const sendSocialPostFieldsToServer = (socialPostText,aISocialPost) => {
    const endpoint = '/store-socialPost'; // Replace with the URL of your endpoint

    const workExperienceObject = Object.assign({}, workExperience);
    
    let workExperienceText = 'Work Experience:\n';
    workExperience.forEach((experience) => {
      workExperienceText += `${experience.position}, ${experience.company}\n `;
      workExperienceText += `Dates: ${experience.startDate} - ${experience.endDate}\n `;
      workExperienceText += `Responsibilities: ${experience.responsibilities}\n `;
      workExperienceText += '\n';
    });

    let educationText = 'Education:\n';
    education.forEach((edu) => {
      educationText += `${edu.degree}, ${edu.fieldOfStudy}, ${edu.school}\n `;
      educationText += `Dates: ${edu.startDate} - ${edu.endDate}\n `;
      educationText += '\n';
    });
    ////console.log(educationText);
    let skillsText = 'Skills:\n ';
     skills.forEach((skill) => {
      skillsText += `${skill}`;
    }); 
    //let skillsText = `Skills:${skills}`;
    ////console.log(skillsText);

    let socialMediaPlatformStore = stripSQLInjection(socialMediaPlatform);
    let intentStore = stripSQLInjection(intent);
    let toneStore = stripSQLInjection(tone);
    let pastedSocialPostStore = stripSQLInjection(pastedSocialPost);
    let aISocialPostStore = stripSQLInjection(aISocialPost);
 
    const data = {sessionId: auth.sessionId,emailAddress:emailAddress, socialMediaPlatform:socialMediaPlatformStore, intent:intentStore, tone:toneStore, social_post_text: pastedSocialPostStore,aISocialPost: aISocialPostStore,unstrippedAISocialPost:aISocialPost };
    
    ////console.log(data);
    axios.post(endpoint, data)
      .then((response) => {
        // The fields for the email were successfully saved to the server
        ////console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const generateSocialPostText = () => {
    // Generate the text for the email
    let text = `Name: ${personalInfo.name}\n`;
    text += `Email: ${personalInfo.email}\n`;
    text += `Location: ${personalInfo.location}\n`;
    text += `Phone: ${personalInfo.phone}\n`;
    text += '\n';

    text += 'Work Experience:\n';
    workExperience.forEach((experience) => {
      text += `${experience.position}, ${experience.company}\n`;
      text += `Dates: ${experience.startDate} - ${experience.endDate}\n`;
      text += `Responsibilities: ${experience.responsibilities}\n`;
      text += '\n';
    });

    text += 'Education:\n';
    education.forEach((edu) => {
      text += `${edu.degree}, ${edu.fieldOfStudy}, ${edu.school}\n`;
      text += `Dates: ${edu.startDate} - ${edu.endDate}\n`;
      text += '\n';
    });

    text += 'Skills:\n';
    skills.forEach((skill) => {
      text += `${skill}`;
    });

    return text;
  };

 

  useEffect(() => {
    // Generate the text for the email and update the state
    ////console.log("workexperiencePREVIEW:");
    ////console.log(workExperience);

    ////console.log(pasted);
    ////console.log(pastedSocialPost);
    if(pasted){
      setSocialPostText(pastedSocialPost);
    }else{
    setSocialPostText(generateSocialPostText());
    }
  }, [personalInfo, workExperience, education, skills]);




  const generateSocialPost = async () => {
    if( auth.loggedIn==1 ){//logged in
     if ((localCredits + auth.credits<1) && !isSubscribed ) {
       alert('You are not subscribed.  Please subscribe before generating a premium social media post.');
       handleOpenModal();
       //setTriggerBuyButtonAlert(true);
       return;
     }
    }else{ // not logged in

      alert('Sign up and Login before taking this action');
      window.location.replace("/#/signupform");

    /* if (localCredits<1) {
      alert('You have no credits. Please make a payment before generating a social media post.');
      return;
    } */
   }
    setIsLoading(true);

    if(pasted){
      setSocialPostText(pastedSocialPost);
    }else{
    setSocialPostText(generateSocialPostText());
    }
    


   try {

     //const prompt = `Create a social media post for ${socialMediaPlatform}  on the topic of: '${socialPostText}' with intent of:'${intent}' and in the tone of: '${tone}'. Improve this post by making it impactful.`;
     const prompt = `Create a social media post on ${socialMediaPlatform} about '${socialPostText}' using a '${tone}' tone. Improve this post by making it impactful.`;

/*      const response = await openai.createCompletion({
      model: 'text-davinci-003',
      prompt: prompt,
      temperature: 0.7,
      max_tokens: 2000,
      top_p: 1,
      frequency_penalty: 0.1,
      presence_penalty: 0.1,

    }); */
/*     const response = await openai.createChatCompletion({
      model: 'gpt-3.5-turbo',
      messages: [{"role": "user", "content": prompt}]
      //temperature: 0.7,
      //max_tokens: 2000,
      //top_p: 1,
      //frequency_penalty: 0,
      //presence_penalty: 0,

   }); */

   const response = await axios.post(
    'https://geniusbrain.zapto.org:4433/generate',
    {
      text:  prompt },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${BrainGeniusAPIKey}` // Use Genius Brain API key
      }
    }
  );
     if(response.status == 429){
      alert("issue with server: too many request. Please try again later.");
     }



     if(localCredits>=1 && response.status == 200){
      //  const newCreditCount = auth.credits - 1;
        decrementLocalCredits()
       // setLocalCredits(newCreditCount);
       }else{
        if(response.status == 200){
        axios.post('/subtract-credit', {sessionId:auth.sessionId},{
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true // This sends the cookie with the request
        })
          .then((response) => {
            // show success message or update the credit count in the UI
          axios.post('/get-credits', {sessionId:sessionId},{
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            withCredentials: true // This sends the cookie with the request
          })
              .then(response => {
                // set the credit balance to the credits state
                setAuth({
                  ...auth,
                  credits: response.data.credits, // Update the credits field
                });
              })
              .catch(error => {
                console.error(error);
              });
          })
          .catch((error) => {
            //////console.log(error);
            // show error message
          }); 
        };
      };

     setIsLoading(false);
     const generatedCoverLetter = response.data.generated_text;
     // Make the POST request to the insert-cover-letter route

 
     setAISocialPost(generatedCoverLetter);
     setDocumentOpen(true);
     sendSocialPostFieldsToServer( pastedSocialPost,generatedCoverLetter)
    // sendEmail(emailAddress, generatedCoverLetter);
    sendEmailServerSide(emailAddress, response.data.generated_text,"document","Social Media Post");
   } catch (error) {
     console.error(error);
     alert("Error processing: you were not charged.");
     setIsLoading(false);
     try{
     axios.post('/log-error', {  sessionId: auth.sessionId, page:"social post generator",errorCode:error }, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      withCredentials: true // This sends the cookie with the request
    })
      .then(response => {
        // The social media post was inserted successfully
      })
      .catch(error => {
        console.error(error);
      });
    }catch{
      console.error("error logging error");
    }
   }
   setIsLoading(false);
 };





 const generateSocialPostSubscribed = async () => {
  if( auth.loggedIn==1 ){//logged in
/*    if (localCredits + auth.credits<1) {
     alert('You have no credits.Please get some credits before generating a premium social media post.');
     handleOpenModal();
     //setTriggerBuyButtonAlert(true);
     return;
   } */
  }else{ // not logged in

    alert('Sign up and Login before taking this action');
    window.location.replace("/#/signupform");

  /* if (localCredits<1) {
    alert('You have no credits. Please make a payment before generating a social media post.');
    return;
  } */
 }
  setIsLoading(true);

  if(pasted){
    setSocialPostText(pastedSocialPost);
  }else{
  setSocialPostText(generateSocialPostText());
  }
  


 try {

   //const prompt = `Create a social media post for ${socialMediaPlatform}  on the topic of: '${socialPostText}' with intent of:'${intent}' and in the tone of: '${tone}'. Improve this post by making it impactful.`;
   const prompt = `Create a social media post on ${socialMediaPlatform} about '${socialPostText}' using a '${tone}' tone. Improve this post by making it impactful.`;

/*      const response = await openai.createCompletion({
    model: 'text-davinci-003',
    prompt: prompt,
    temperature: 0.7,
    max_tokens: 2000,
    top_p: 1,
    frequency_penalty: 0.1,
    presence_penalty: 0.1,

  }); */
/*   const response = await openai.createChatCompletion({
    model: 'gpt-3.5-turbo',
    messages: [{"role": "user", "content": prompt}]
    //temperature: 0.7,
    //max_tokens: 2000,
    //top_p: 1,
    //frequency_penalty: 0,
    //presence_penalty: 0,

 }); */

 const response = await axios.post(
    'https://geniusbrain.zapto.org:4433/generate',
    {
      text:  prompt },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${BrainGeniusAPIKey}` // Use Genius Brain API key
      }
    }
  );
   if(response.status == 429){
    alert("issue with server: too many request. Please try again later.");
   }


/* 
   if(localCredits>=1 && response.status == 200){
    //  const newCreditCount = auth.credits - 1;
      decrementLocalCredits()
     // setLocalCredits(newCreditCount);
     }else{
      if(response.status == 200){
      axios.post('/subtract-credit', {sessionId:auth.sessionId},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
        .then((response) => {
          // show success message or update the credit count in the UI
        axios.post('/get-credits', {sessionId:sessionId},{
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          withCredentials: true // This sends the cookie with the request
        })
            .then(response => {
              // set the credit balance to the credits state
              setAuth({
                ...auth,
                credits: response.data.credits, // Update the credits field
              });
            })
            .catch(error => {
              console.error(error);
            });
        })
        .catch((error) => {
          //////console.log(error);
          // show error message
        }); 
      };
    }; */

   setIsLoading(false);
   const generatedCoverLetter = response.data.generated_text;
   // Make the POST request to the insert-cover-letter route


   setAISocialPost(generatedCoverLetter);
   setDocumentOpen(true);
   sendSocialPostFieldsToServer( pastedSocialPost,generatedCoverLetter)
  // sendEmail(emailAddress, generatedCoverLetter);
  sendEmailServerSide(emailAddress, response.data.generated_text,"document","Social Media Post");
 } catch (error) {
   console.error(error);
   alert("Error processing: you were not charged.");
   setIsLoading(false);
   try{
   axios.post('/log-error', {  sessionId: auth.sessionId, page:"social post generator",errorCode:error }, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    withCredentials: true // This sends the cookie with the request
  })
    .then(response => {
      // The social media post was inserted successfully
    })
    .catch(error => {
      console.error(error);
    });
  }catch{
    console.error("error logging error");
  }
 }
 setIsLoading(false);
};


 return (
  <Container>
  <div>
    <h3 className="mx-auto text-center">Generate Social Post</h3>
    <br />
    <h3 className="text-center">Social Media Platform name</h3>
    <p className="text-center">(optional)Enter the social media platform you are going to post to:</p>
    <div className="d-flex justify-content-center">
      <input
        className="mx-auto text-center"
        value={socialMediaPlatform}
        style={{ height: "25", width: "200px", maxWidth: "100%" }}
        onChange={(e) => setSocialMediaPlatform(e.target.value)}
      />
    </div>
 {/*    <br />
    <br />
    <h3 className="text-center">Intent of post</h3>
    <p className="text-center">(optional)Enter the intent of the post:</p>
    <p className="text-center">
      (example: 'to make sales', 'get leads', 'entertain', 'educate')
    </p>
    <div className="d-flex justify-content-center">
      <input
        className="mx-auto text-center"
        value={intent}
        style={{ height: "25", width: "200px", maxWidth: "100%" }}
        onChange={(e) => setIntent(e.target.value)}
      />
    </div> */}
    <br />
    <br />
    <h3 className="text-center">Tone</h3>
    <p className="text-center">(optional)(Enter intended tone of the post:)</p>
    <p className="text-center">
      (example: 'profesional', 'persuasive', 'happy', 'motivational')
    </p>
    <div className="d-flex justify-content-center">
      <input
        className="mx-auto text-center"
        value={tone}
        style={{ height: "25", width: "200px", maxWidth: "100%" }}
        onChange={(e) => setTone(e.target.value)}
      />
    </div>
    <br />
    <br />
    <h3 className="text-center">Email Address</h3>
    <p className="text-center">(Enter your email address:)</p>
    <div className="d-flex justify-content-center">
      <input
        className="mx-auto text-center"
        value={ emailAddress}
        style={{ height: "25", width: "200px", maxWidth: "100%" }}
        onChange={(e) => setEmailAddress(e.target.value)}
      />
    </div>
    <br />
    <br />
 {/*      {auth.loggedIn==1 ?(localCredits>=1 ? <div style={{ color:'#257883' }}>
          Account Credits: {auth.credits } + non account credits:{localCredits}
          </div> :  <div style={{ color:'#257883' }}> Account Credits: {auth.credits } </div> ) : <div style={{ color:'#b73133' }}>
          Credits: {localCredits}
      </div>} */}
    <div className="d-flex justify-content-center">
      { isSubscribed ? (isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-primary" onClick={generateSocialPostSubscribed}>Generate Official SocialPost</button> ))
      :(isLoading ? ( <div><FaSpinner icon="spinner" className="spinner" /> Loading...</div> ) : (<button className="btn btn-primary" onClick={generateSocialPost}>Generate Official SocialPost ( Member Only ) </button> ))}
      </div>  
      {/* <button onClick={() => sendSocialPostFieldsToServer(personalInfo, workExperience, education, skills, socialPostText)}>Save Mock SocialPost</button> */}
      {aISocialPost && <div style={{ justifyContent: 'center',backgroundColor: '#fafafa', fontStyle: 'italic',whiteSpace: 'pre-wrap', textIndent: '50px' }}>{aISocialPost}</div>}
      {/* <MyAlert trigger={triggerBuyButtonAlert}/> */}
      {documentOpen ?  <MyAlertDocument documentOpen={documentOpen}  setDocumentOpen={setDocumentOpen} handleDocumentClose={handleDocumentCloseModal} documentFile={aISocialPost}/> : null};

      {open ? <MyAlertMessage open={open}  setOpen={setOpen} handleClose={handleCloseModal}/> : null};
  {/*      <PersonalInfo personalInfo={personalInfo} />
      <WorkExperience workExperience={workExperience} />
      <Education education={education} />
      <Skills skills={skills} /> */} 
    </div>
    </Container>
  );
};

export default SocialPostPreview;
