import React, { useState,useEffect,useContext } from 'react';
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import axios from './api/axios';
import AuthContext from "./context/AuthProvider";
import "./BuyCredits.css";
import storeActivity from './functions/storeActivity.jsx';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import AuthenticatedComponent from './components/AuthenticatedComponent';

const BuyCredits = () => {

  const [buyCredits, setBuyCredits] = useState(0);  
  const [buyCost, setBuyCost] = useState(0.00);  
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [username, setUsername] = useState('');
  const [credits, setCredits] = useState(0);
  const [loggedIn, setLoggedIn] = useState('');
  const [sessionId, setSessionId] = useState('');
  const { auth,setAuth } = useContext(AuthContext);

  const payPalClientId = process.env.REACT_APP_PAY_PAL_API_KEY1
  useEffect(() => {   
    
    setUsername(localStorage.getItem('username'));
    setCredits(localStorage.getItem('credits'));
    setLoggedIn(localStorage.getItem('loggedIn'));
    setSessionId(localStorage.getItem('sessionId'));
  });

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'BuyCredits'             ,'Page'              ,'Landed on BuyCredits page');
  }, []);

  const prices = {
    2: 9.99,
    //2:0.01,
    5: 14.99,
    10: 19.99,
    20: 29.99,
    50: 39.99,
  };


  const sendEmailServerSide = async (email, coverLetter,emailType,documentType) => {
    try {
      await axios.post('/send-email', {sessionId:auth.sessionId,email:email,message:coverLetter,emailType:emailType,documentType:documentType},{
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        withCredentials: true // This sends the cookie with the request
      })
    } catch (error) {
      //console.error(error);
    }
  };


const postPurchase = () => {
  axios.post('/get-credits', { sessionId: auth.sessionId }, {
    headers: {'Content-Type': 'application/x-www-form-urlencoded',},
     withCredentials: true // This sends the cookie with the request
  })
    .then(response => {
      // set the credit balance to the credits state
      setAuth({
        ...auth,
        credits: response.data.credits, // Update the credits field
      });
    })
    .catch(error => {
      //console.error(error);
    });
    localStorage.setItem('credits', auth.credits);
}

  const makePurchase = (buyCredits) => {
    // Send a request to your server to buy credits
    axios.post('/buyCredits', { buyCredits:buyCredits,username:username,sessionId:sessionId,payment_method:"PayPal" }, {
        headers: {'Content-Type': 'application/x-www-form-urlencoded',},
         withCredentials: true // This sends the cookie with the request
      })
              .then((response) => {
                // Update the user's credits in the UI
                setCredits(credits + response.data.credits);
                try{sendEmailServerSide(auth.email,buyCredits,"purchase",prices[buyCredits]);}catch(error){}
                alert('Success!');
              })
              .catch((error) => {
                //console.error(error);
                // Show an error message to the user
                alert('There was an error making the purchase. Please email the admin at careergeniusapp@gmail.com and we will fix the issue.');
              });
             
  }

  const HandlePayPaleButtonPress = (event) => {
    try{
      storeActivity( auth.sessionId       ,"Button Press"     ,'BuyCredits'             ,'Button'              ,`Pressed PayPal BuyButton`);
    }catch(error){}
  }

  const handleCreditSelection = (event) => {
    let creditsToBuy = event.target.value;
    let cost = prices[creditsToBuy];  
    try{
      storeActivity( auth.sessionId       ,"Button Press"     ,'BuyCredits'             ,'Button'              ,`Pressed ${creditsToBuy} credits`);
    }catch(error){}
   // try{sendEmailServerSide(auth.email, creditsToBuy,"purchase",prices[creditsToBuy]);}catch(error){}
    setBuyCost(cost);
    setBuyCredits(creditsToBuy);
  }

  return (
    <div className="container">
      <AuthenticatedComponent>
      </AuthenticatedComponent>
    <header>
      <h1>Purchase Credits</h1>
    </header>
    <div className="row align-items-start">
      <div className="col-md-8">
        <p>Credits can be used to generate optimized custom cover letter's, resumes, or to access other premium features on our site. Choose the option that best fits your needs:</p>
       <p style={{}}>Select credit option below:</p>
        <div className=" btn-group-toggle" style={{/* textAlign:"center" */}}>
   {/*        <label className="btn btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value={"2"} onChange={handleCreditSelection} />
            2 Credits - $2.00
          </label> */}
          <br />
          <label className="btn  buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="2" onChange={handleCreditSelection} />
            2 Credits - <span className="price">$20</span> <span className="discounted-price">${prices[2]}</span>
          </label>
          <br />
          <label className="btn buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="5" onChange={handleCreditSelection} />
            5 Credits - <span className="price">$40</span> <span className="discounted-price">${prices[5]}</span>
          </label>
          <br />
          <label className="btn buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="10" onChange={handleCreditSelection} />
            10 Credits - <span className="price">$80</span> <span className="discounted-price">${prices[10]}</span>
          </label>
          <br />
          <label className="btn buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="20" onChange={handleCreditSelection} />
            20 Credits - <span className="price">$160</span> <span className="discounted-price">${prices[20]}</span>
          </label>
          <br />
{/*           <label className="btn btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="60" onChange={handleCreditSelection} />
            60 Credits - $60.00
          </label>
          <br /> */}
          <label className="btn buyCredits btn-outline-primary btn-block">
            <input type="radio" name="buyCredits" value="50" onChange={handleCreditSelection} />
            Business Package: 50 Credits - <span className="price">$200</span> <span className="discounted-price">${prices[50]}</span>
          </label> 
          <br />
          <br />
        </div>
      </div>
   
      <div className="col-md-4">
        <p>Purchase Quantity: {buyCredits} credits {buyCredits >0 ? (<span>for ${buyCost} </span>):null}</p>
        {buyCredits > 0 && (
          <PayPalScriptProvider
            options={{ "client-id": payPalClientId }}
          >
            <PayPalButtons 
              style={{
                layout: 'horizontal',
                color: 'blue',
                shape: 'rect',
                label: 'pay',
                size: 'medium',
              }} onClick={HandlePayPaleButtonPress}
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: `${buyCost}`,
                      },
                    },
                  ],
                });
              }}
              onApprove={async (data, actions) => {
                const details = await actions.order.capture();
                const name = details.payer.name.given_name;
                //////console.log('Payment success', details);
                axios.post('/buyCredits', { buyCredits:buyCredits,username:username,sessionId:sessionId,payment_method:"PayPal" }, {
                    headers: {'Content-Type': 'application/x-www-form-urlencoded',},
                     withCredentials: true // This sends the cookie with the request
                  })
                          .then((response) => {
                            // Update the user's credits in the UI
                            setCredits(credits + response.data.credits);
                            setAuth({
                              ...auth,
                              credits: credits + response.data.credits // Update the credits field
                            });
                            console.log("auth.credits");
                            localStorage.setItem('credits', auth.credits);
                            try{sendEmailServerSide(auth.email,buyCredits,"purchase",prices[buyCredits]);}catch(error){};
                            postPurchase();
                            alert('Success!');
                            
                            //window.location.replace("/");
                          })
                          .catch((error) => {
                            //console.error(error);
                            // Show an error message to the user
                            alert('There was an error making the purchase. Please email the admin at careergeniusapp@gmail.com and we will fix the issue.');
                          });
                          
              }}
              onCancel={async (data, actions) => {
                  setBuyCredits(0)
                  setBuyCost(0)
                  alert('Payment was cancelled');
                }}
               
            />
          </PayPalScriptProvider>
      )}
      
      {paymentStatus > 0 && <p>Payment successful! Thank you for your purchase.</p>}
      { 
        auth.user_role_type == "tester" ?
      <div> <button onClick={() => makePurchase(buyCredits)}>Buy {buyCredits} credits</button></div>
      :null

}

      <footer>
    <p>{/* Don't need that many credits? You can also purchase a single credit for $2.00. */}</p>
    </footer>
    </div>
    </div>
    </div>
  );
}

export default BuyCredits
