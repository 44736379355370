import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import storeActivity from '../functions/storeActivity.jsx';
import AuthContext from '../context/AuthProvider';
//import './ResumeHelp.css';
import { Container } from 'react-bootstrap';

export const ProfessionalResumeWriters = () => {
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    storeActivity(auth.sessionId, 'Page Landing', 'Professional Resume Writers', 'Page', 'Landed on Professional Resume Writers page');
  }, []);

  return (
    <Container>
      <div className="resume-help-container text-center">
        <h1 className="mb-4">Get the Best Professional Resume Writers at CareerGenius.org</h1>
        <p className="mb-5">
          Looking for the best professional resume writers to take your job search to the next level? Look no further than CareerGenius.org! Our expert writers utilize AI technology to craft customized resumes tailored specifically to the job you're applying for, ensuring that you stand out from the competition. 
        </p>
        <div>
  <p>
    A professional resume writer can help you create a well-written and formatted resume, but they may not have access to the latest technology and resources available to CareerGenius.org's AI-powered resume builder. Here are some ways that CareerGenius.org's resume builder might be seen as an advantage:
  </p>
  <ul>
    <li>
      <strong>Customization:</strong> CareerGenius.org's AI technology uses machine learning algorithms to analyze job descriptions and tailor your resume to the specific requirements of each job you apply for. This means that you don't have to manually customize your resume for each job application, saving you time and ensuring that your resume is always optimized for the position you're applying for.
    </li>
    <li>
      <strong>Keyword optimization:</strong> Many companies use applicant tracking systems (ATS) to screen resumes, and it's important to include relevant keywords in your resume to ensure it makes it past the ATS. CareerGenius.org's AI technology can analyze job descriptions and identify the most important keywords, ensuring that your resume is optimized for ATS and increasing your chances of landing an interview.
    </li>
    <li>
      <strong>Data-driven insights:</strong> CareerGenius.org's AI technology provides data-driven insights into your resume, including suggestions for improvement, so you can make informed decisions about how to optimize your resume for maximum impact.
    </li>
    <li>
      <strong>Speed and efficiency:</strong> With CareerGenius.org's AI-powered resume builder, you can create a professional-looking resume in just a few minutes, without the need for extensive research or experience in resume writing. This can save you time and help you quickly get your resume in front of hiring managers.
    </li>
  </ul>
  <p>
    While professional resume writers can certainly help you create a well-written and formatted resume, CareerGenius.org's AI-powered resume builder offers a unique advantage by leveraging the latest technology and resources to ensure that your resume is optimized for maximum impact.
  </p>
</div>

        <h2 className="mb-4">Why Choose CareerGenius.org for Your Professional Resume Writers?</h2>
        <ul className="mb-5">
          <li>AI-powered technology for optimized resumes</li>
          <li>Customized templates to fit your unique needs and job target</li>
          <li>Affordable pricing options to meet your budget</li>
          <li>Dedicated customer support for all of your questions and concerns</li>
        </ul>
        <h2 className="mb-4">Get the Best Professional Resume Writers at CareerGenius.org Today!</h2>
        <p className="mb-5">
          Ready to take your job search to the next level? Fill out our quick and easy online form at CareerGenius.org and one of our expert professional resume writers will be in touch to help you create the best resume for your job search.
        </p>

        <Link to="/" className="btn btn-primary">
          Home
        </Link>
      </div>
    </Container>
  );
};

export default ProfessionalResumeWriters;
