import React,{useEffect,useContext,useState,useRef,useCallback} from 'react';
import axios from '../api/axios';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import AuthContext from "../context/AuthProvider";
import Copy from '../assets/copy.png'
import Copied from '../assets/copiedToClipBoard.png'
import BuyCredits from '../BuyCredits.jsx';
import Subscribe from '../Subscribe.jsx';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import AuthenticatedComponent from '../components/AuthenticatedComponent';
// import {DocumentEditor} from './DocumentEditor';
import './MyAlert.css';
// import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
// import { Preview, print } from 'react-html2pdf';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas'
// import { render, Text } from 'redocx'
// import { saveAs } from 'file-saver';
// import htmlDocx from 'html-docx-js';
// import docx, {Packer,createElement}  from "docx";
// import { useReactToPrint } from "react-to-print";


// import Quill from "quill";
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import htmlEditButton from "quill-html-edit-button";
// Quill.register("modules/htmlEditButton", htmlEditButton);


export function MyAlert({trigger}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  


  return ( trigger === false ?
    <div></div> : 
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={trigger}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Get some credits to use this feature: "
        action={
          <React.Fragment>
            <Button variant=" btn primary"  as={Link} to="/buycredits" style={{
        fontSize: '16px',
        fontWeight: 'normal',
        color: 'white',
        backgroundColor: '#257883',
        textDecoration: 'none',
        marginRight: '20px',
      }}>Buy Credits</Button>
          </React.Fragment>
        }
      /> 
    </div>
  )  ;

}


export function MyAlertMessage({open,setOpen,handleCloseModal}) {
    const { auth,setAuth } = useContext(AuthContext);

    //console.log(open);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        maxheight:'90%', 
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }; 

      const handleClick = () => {
        setOpen(false);
      };
  
      return (
        <div style={{OverflowY:"scroll"}}>
            
        <Modal
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
              <div className="d-flex justify-content-center">
                {/* {auth.loggedIn==1 ? <div style={{ justifyContent: 'center', color:'#257883'}}> Account Credits: {auth.credits } </div> : null} */}
              </div>
              <br/>
              {/* <BuyCredits/> */}
              <Subscribe/>
              <Button variant="outlined" onClick={handleClick}>Close</Button>
          </Box>
        </Modal>
        
        </div>
      );
  
  }


  export function MyAlertDocument({ documentOpen, setDocumentOpen, handleDocumentCloseModal, documentFile,documentDifferences }) {
    const { document } = require("global");
    const { auth, setAuth } = useContext(AuthContext);
    const [copyImage, setCopyImage] = useState(Copy);
    const [state, setState] = useState({
      fileType: "text",
      fileDownloadUrl: null,
      status: ""
    });
  
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "80%",
      maxheight: '90%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };
  
    const defaultFileType = "text";
    const fileNames = {
      json: "document.json",
      csv: "document.csv",
      text: "document.txt"
    };
  
    const handleClick = () => {
      setDocumentOpen(false);
    };
  
    const handleCopyClick = () => {
      navigator.clipboard.writeText(documentFile);
      setCopyImage(Copied);
    };
  
    const download = () => {
      const blob = new Blob([documentFile]);
      const fileDownloadUrl = URL.createObjectURL(blob);
  
      setState({ fileDownloadUrl });
  
      const link = document.createElement("a");
      link.href = fileDownloadUrl;
      link.download = fileNames[defaultFileType];
      link.click();
  
      URL.revokeObjectURL(fileDownloadUrl);
    };
  
    return (
      <div className='modal-body'>
             <AuthenticatedComponent>
             </AuthenticatedComponent>
        <Modal
          open={documentOpen}
          onClose={handleDocumentCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modal-body' sx={style}>
            <div className="d-flex justify-content-center">
            
              <h3 className="mx-auto text-center">Your new document</h3>
              <p className="mx-auto text-center" > Be sure to read over changes to new document to ensure accuracy and quality. </p>
              {auth.loggedIn === 1 ? <div style={{ justifyContent: 'center', color: '#257883' }}> Account Credits: {auth.credits} </div> : null}
            </div>
            <Paper>
            {documentDifferences && <div style={{ justifyContent: 'center', backgroundColor: '#fafafa',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}>{documentDifferences}</div>}
            </Paper>
            <div style={{ justifyContent: 'center', backgroundColor: 'white',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}><br/></div>
            <br />
            <div className="d-flex justify-content-center">
            <Button variant="outlined"onClick={download}>
              Download document
            </Button>
            </div>
          
            <img src={copyImage} alt="copy to clipboard" className="copyToClipboardImg" onClick={handleCopyClick} />
            <Paper>
            {documentFile && <div style={{ justifyContent: 'center', backgroundColor: '#fafafa', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}>{documentFile}</div>}
            </Paper>
            <p className="mx-auto text-center"> If you have any questions or issues with quality please reach out at <a href="mailto:careergeniusapp@gmail.com">careergeniusapp@gmail.com</a> and we will be happy to help in any way we can. </p>
            <br />
            <Button variant="outlined" onClick={handleClick}>Close</Button>
          </Box>
        </Modal>
      </div>
    );
  }
  




   export function MyAlertSubscription({IsSubscriptionOpen, setIsSubscriptionOpen}) {
    const { document } = require("global");
    const { auth, setAuth } = useContext(AuthContext);
    const [copyImage, setCopyImage] = useState(Copy);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [subscriptionStatusPayPal, setSubscriptionStatusPayPal] = useState(null);  
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);
    const [timeLeft, setTimeLeft] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0); // Key to trigger refresh

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: "80%",
      maxheight: '90%',
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };


    useEffect(() => {
      getSubscription();
    }, []); 


 



  useEffect(() => {

    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.post('/get-subscription-status', {
          sessionId: auth.sessionId,
        });
        setSubscriptionStatus(response.data.status);
        //if (response.data.status === 4 && response.data.next_payment_date) {
          setTimeLeft(response.data.next_payment_date);
        //}
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };
   

    const fetchSubscriptionStatusPayPal = async () => {
      try {
        await getSubscription();
        const response = await axios.post('/get-subscription-status-from-paypal', {
          subId:subscriptionId,
          username: auth.username
        });
        console.log(response.data.status);
        setSubscriptionStatusPayPal(response.data.status);
        //setNextBillingTimePayPal(response.data.nextBillingTime);
      } catch (error) {
        console.error('Error fetching subscription status:', error);
      }
    };

    if (subscriptionId) {
      fetchSubscriptionStatusPayPal();
    } else {
      getSubscription().then(() => fetchSubscriptionStatusPayPal());
    }
    fetchSubscriptionStatus();

  }, [auth.sessionId, subscriptionId, refreshKey]);


  

  
  const formatTimeLeft = (milliseconds) => {
    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
    const hours = Math.floor((milliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  const getSubscription = async () => {
    const {sessionId} = auth;
     try {
       const response = await axios.post('/get-subscription', { sessionId,});
       setSubscriptionId(response.data.subscriptionId);
       return response.data;
     } catch (error) {
       console.error(error);
     }
   };

    const handleClick = () =>{
      setIsSubscriptionOpen(false);
    }

    const handleCancelSubscription = async () =>{
      const {sessionId} = auth;
      try {
        const { data } = await axios.post('/cancel-subscription', { sessionId:sessionId, subId :subscriptionId});
        alert("Subscription canceled. You will have access until end of pay period.");
        console.log(data);
        setRefreshKey(prevKey => prevKey + 1); // Trigger a refresh
      } catch (error) {
        alert("Your cancelation request was recieved we will email you a confirmation once it is fully processed.");
        console.error(error);
      }
    }

    const handleRenewSubscription = async () => {
      const { sessionId } = auth;
      try {
        const { data } = await axios.post('/renew-subscription', { sessionId, subId: subscriptionId });
        alert("Subscription renewed successfully.");
        console.log(data);
        setRefreshKey(prevKey => prevKey + 1); // Trigger a refresh
      } catch (error) {
        alert("Failed to renew subscription.");
        console.error(error);
      }
    };

    return (
      <div className='modal-body medium'>
        <Modal
          open={true}
          //onClose={handleDocumentCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className='modal-body' sx={style}>
            <div className="d-flex justify-content-center">
            
              <h3 className="mx-auto text-center">Your Subscription</h3>
               <p className="mx-auto text-center" > You are on the {auth.user_role_type} </p> 
              {/* {auth.loggedIn === 1 ? <div style={{ justifyContent: 'center', color: '#257883' }}> Account Credits: {auth.credits} </div> : null} */}
            </div>
            <Paper>
            {/* {documentDifferences && <div style={{ justifyContent: 'center', backgroundColor: '#fafafa',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}>{documentDifferences}</div>} */}
            </Paper>
            <div style={{ justifyContent: 'center', backgroundColor: 'white',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}><br/></div>
            <br />
            <div className="d-flex justify-content-center">
            {/* <Button variant="outlined"onClick={download}>
              Download document
            </Button> */}

<div>
      {/* <h2>Subscription Status</h2>
      {subscriptionStatus === 1 && <p>Your subscription is active.</p>}
      {subscriptionStatus === 4 && (
        <div>
          <p>Your subscription is canceled.</p>
          {timeLeft > 0 ? (
            <p>Time left until expiration: {formatTimeLeft(timeLeft)}</p>
          ) : (
            <p>Your subscription has expired.</p>
          )}
        </div>
        

      )} */}


      

      <h2>Subscription Status</h2>
              {subscriptionStatusPayPal === 'ACTIVE' && <p>Your subscription is active.</p>}
              {(subscriptionStatusPayPal === 'CANCELLED' || subscriptionStatusPayPal === 'SUSPENDED') && (
                <div>
                  <p>Your subscription is canceled.</p>
                  {!subscriptionStatus && <p>Loading...</p>}
                    {subscriptionStatus === 1 && <p>You will have access until the end of your pay period. 
                     
                        Access expiration: {new Date(timeLeft).toLocaleDateString()}</p>
                      
                    }
                    <div className="d-flex justify-content-center my-3">
                      <Button variant="outlined" onClick={handleRenewSubscription}>
                        Renew Subscription
                      </Button>
                    </div>
                  
                  {/* {nextBillingTime ? (
                    <p>Next billing time: {new Date(nextBillingTime).toLocaleString()}</p>
                  ) : (
                    <p>Your subscription has expired.</p>
                  )} */}
                   </div>
              )}

      {!subscriptionStatusPayPal && <p>Loading...</p>}
    </div>

</div>
      <div className="d-flex justify-content-center my-3">
      {subscriptionStatusPayPal === 'ACTIVE' && <Button variant="outlined" onClick={handleCancelSubscription}>Cancel Subscription</Button> }
      </div>
          
            {/* <img src={copyImage} alt="copy to clipboard" className="copyToClipboardImg" onClick={handleCopyClick} /> */}
            <Paper>
            {/* {documentFile && <div style={{ justifyContent: 'center', backgroundColor: '#fafafa', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}>{documentFile}</div>} */}
            </Paper>
   {/*          <p className="mx-auto text-center"> To cancel subscription please send and email a request to: <a href="mailto:careergeniusapp@gmail.com">careergeniusapp@gmail.com</a> and we will send you back a confirmation. </p>
 */}
            <p className="mx-auto text-center"> If you have any issues with your account or canceling please email <a href="mailto:careergeniusapp@gmail.com">careergeniusapp@gmail.com</a> and we will assist. </p>
            <br />
            <Button variant="outlined" onClick={handleClick}>Close</Button> 
          </Box>
        </Modal>
      </div>
    );
  }



  


//   export function MyAlertEditDocument({ documentOpen, service, docId, setDocumentOpen, handleDocumentCloseModal, documentFile,documentDifferences }) {
//     const { document } = require("global");
//     const child_process = require('child_process');
//     const pdf = require('html-pdf');
//     const { auth, setAuth } = useContext(AuthContext);
//     const [copyImage, setCopyImage] = useState(Copy);
//     const [savedEdit, setSavedEdit] = useState(false);
//     const [text, setText] = useState('');

// /*     function handleHtmlChange(content) {
//       setHtmlContent(content);
//     } */
  

//     //const doc = new jsPDF();
//     const [state, setState] = useState({
//       fileType: "txt",
//       fileDownloadUrl: null,
//       status: ""
//     });

//     const striptags = require('striptags');
  
//     const style = {
//       position: 'absolute',
//       top: '50%',
//       left: '50%',
//       transform: 'translate(-50%, -50%)',
//       width: "80%",
//       maxheight: '90%',
//       bgcolor: 'background.paper',
//       border: '2px solid #000',
//       boxShadow: 24,
//       p: 4,
//     };
  
//     const defaultFileType = "text";
//     const fileNames = {
//       json: "document.json",
//       csv: "document.csv",
//       text: "document.txt"
//     };
  
//     const handleClick = () => {
//       setDocumentOpen(false);
//       console.log("handleClick");
 
//     };


 
  
  
//     const handleCopyClick = () => {
//       console.log("documentFile");
//       console.log(documentFile);
//       const parser = new DOMParser();
//       const doc = parser.parseFromString(documentFile, 'text/html');
//       const text = doc.body.textContent;
//       /* navigator.clipboard.writeText(documentFile);
//        */
//       navigator.clipboard.writeText(text);
//       setCopyImage(Copied);
//     }; 
 
// // Use the built-in DOMParser
// function convertQuillClassesToInlineStyles(htmlString) {
//   const container = document.createElement('div');
//   container.innerHTML = htmlString;

//   const elements = container.querySelectorAll('[class^="ql-"]');
//   console.log("elements");
//   console.log(elements);
//   elements.forEach((element) => {
//     console.log("element");
//     console.log(element);
//     const classNames = element.className.match(/ql-[a-zA-Z0-9-]*/g) || [];
//     console.log("classNames");
//     console.log(classNames);
//     const styles = [];
//     for (let i = 0; i < classNames.length; i++) {
//       const className = classNames[i];
//       const style = className.replace('ql-align-center', 'text-align: center');
  
//       const value = element.style.getPropertyValue(style);
//       styles.push(`${style}`);
//       // styles.push(`${style}: ${value}`);
//     }
//     const styleString = styles.join('; ');
//     element.removeAttribute('class');
//     element.setAttribute('style', styleString);
//   });
//   console.log("container.innerHTML");
//   console.log(container.innerHTML);
//   return container.innerHTML;
// }


// const MyComponent = () => {

//   return (
    
//       <div style={{margin:72}} dangerouslySetInnerHTML={{ __html: convertQuillClassesToInlineStyles(text) }}></div> 
   
//   );
// };
// const componentRef = useRef();
// const handlePrint = useReactToPrint({
//   content: () => componentRef.current,
// });

// const parser = new DOMParser();
// // Function to download a docx file from a string of HTML
// const downloadDocx = async (htmlString) => {
//   try {
//   const fileName = 'My Document';

//   axios.post('/create-docx', { htmlString, fileName }, { responseType: 'blob' })
//     .then(response => {
//       const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
//       saveAs(blob, `${fileName}.docx`);
//     })
//     .catch(error => {
//       console.error(error);
//     });
//   } catch (error) {
//     console.error(error);
//   }
// };

// const saveDocument = () => {
// console.log("service");
// console.log(service);
// console.log("docId");
// console.log(docId);

// // write code to save the document html I will need to know document type
// axios.post('/save_document_html', {  sessionId: auth.sessionId,service: service, docId: docId, text: text }, {
//   headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//   withCredentials: true // This sends the cookie with the request
// });
// setSavedEdit(true);
//   if(setSavedEdit){
//     window.location.href = window.location.href;
//   }
// }

// const [pDFContainerVisible,setPDFContainerVisible] = useState(false);

// const print = () => {
 
//       setPDFContainerVisible(true);
//       handlePrint();
//       setPDFContainerVisible(true);
// }

//     const download = () => {
//       // Convert the HTML to an image
//       console.log(state.fileType);
//       console.log(documentFile);
      
//     if(state.fileType === "pdf") {
//       setPDFContainerVisible(true);
//        // const htmlString = documentFile;
//        const htmlString = `<div style="margin: 0 36pt; white-space: pre-wrap;">${documentFile}</div>`;
//        console.log("text");
//         console.log(text);
//         const container = document.createElement('div');
//         container.innerHTML = text;
//         document.body.appendChild(container);

 
//         var doc = new jsPDF("p", "mm", "a4");

//         var width = doc.internal.pageSize.getWidth();
//         var height = doc.internal.pageSize.getHeight();
        
    
//           alert(`Choose "Save as PDF" under 'Printer' when print window appears to save this document as a PDF file`);

//           handlePrint();
        
//   setPDFContainerVisible(true);
//   document.body.removeChild(container);
// }if(state.fileType == "txt"){
//   console.log("documentFile");
//   console.log(documentFile);
//   const parser = new DOMParser();
//   const doc = parser.parseFromString(documentFile, 'text/html');
//   const text = doc.body.textContent;
  
//   const blob = new Blob([text], { type: 'text/plain' });
//   const fileDownloadUrl = URL.createObjectURL(blob);
//   setState({ fileDownloadUrl });
  
//   const link = document.createElement("a");
//   link.href = fileDownloadUrl;
//   link.download = `document.${state.fileType}`;
//   link.click();
  
//   URL.revokeObjectURL(fileDownloadUrl);
//       }if(state.fileType === "doc"){


        
//         const fileName = 'My Document';
//         downloadDocx(text);

// /*         const blob = new Blob([documentFile], { type: 'text/plain' });
//         const fileDownloadUrl = URL.createObjectURL(blob);
//         setState({ fileDownloadUrl });
      
//         const link = document.createElement("a");
//         link.href = fileDownloadUrl;
//         link.download = `document.${state.fileType}`;
//         link.click();
      
//         URL.revokeObjectURL(fileDownloadUrl); */


//       }
//     };

//     const handleFileTypeChange = (event) => {
//       setState({ fileType: event.target.value });
//       console.log(state.fileType);
//     };
    
//     const handleTextChange = (newText) => {
//       setText(newText);
//     };
  
//     return (

//       <div id="modal"  className='modal-body'>
        
//         <Modal
//           open={documentOpen}
//           onClose={handleDocumentCloseModal}
          
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
    
//           <Box className='modal-body' sx={style}>
//             <div className="d-flex justify-content-center">
            
//               <h3 className="mx-auto text-center">Your new document</h3>
//               <p className="mx-auto text-center" > Be sure to read over changes to new document to ensure accuracy and quality. </p>
//               {auth.loggedIn === 1 ? <div style={{ justifyContent: 'center', color: '#257883' }}> Account Credits: {auth.credits} </div> : null}
//             </div>
//             {/* <Paper> */}
//             {/* {documentDifferences && <div style={{ justifyContent: 'center', backgroundColor: '#fafafa',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}>{documentDifferences}</div>} */}
//             {/* <DocumentEditor onTextChange={handleTextChange} document={documentFile}/>
//             </Paper> */}
//             <div style={{ justifyContent: 'center', backgroundColor: 'white',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}><br/></div>
    
//             <Paper >
//             {/* {documentDifferences && <div style={{ justifyContent: 'center', backgroundColor: '#fafafa',color: 'red', fontStyle: 'italic', whiteSpace: 'pre-wrap', textIndent: '50px' }}>{documentDifferences}</div>} */}

//             <div className="d-flex justify-content-center">
//             <img src={copyImage} alt="copy to clipboard" className="copyToClipboardImg" onClick={handleCopyClick} />
  
//             <Button variant="outlined"onClick={print}>
//               Print document
//             </Button>
//             <Button variant="outlined"onClick={download}>
//               Download document
//             </Button>
//             <select value={state.fileType} onChange={handleFileTypeChange}>
//               <option value="txt">Text</option>
//               <option value="doc">DOC</option>
//               <option value="pdf">PDF</option>
//             </select>
//             <Button variant="outlined"onClick={saveDocument}>
//               Save Changes and Close
//             </Button>
//             </div>
//             <DocumentEditor onTextChange={handleTextChange} document={documentFile}/>
//             <p className="mx-auto text-center"> If you have any questions or issues with quality please reach out at <a href="mailto:careergeniusapp@gmail.com">careergeniusapp@gmail.com</a> and we will be happy to help in any way we can. </p>
//             <Button variant="outlined" onClick={handleClick}>Close</Button>
//             </Paper>
//           </Box>
//         </Modal>
       
//         <div ref={componentRef}>
//            <MyComponent />
//         </div>      
//       </div>
 
//     );
//   }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  
  function ChildModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <React.Fragment>
        <Button onClick={handleOpen}>Open Child Modal</Button>
        <Modal
          hideBackdrop
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 200 }}>
            <h2 id="child-modal-title">Text in a child modal</h2>
            <p id="child-modal-description">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </p>
            <Button onClick={handleClose}>Close Child Modal</Button>
          </Box>
        </Modal>
      </React.Fragment>
    );
  }
  
  export  function NestedModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div>
        <Button onClick={handleOpen}>Open modal</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box sx={{ ...style, width: 400 }}>
            <h2 id="parent-modal-title">Text in a modal</h2>
            <p id="parent-modal-description">
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </p>
            <ChildModal />
          </Box>
        </Modal>
      </div>
    );
  }

export default MyAlert;
