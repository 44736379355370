import React, { useState,useEffect,useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import axios from '../api/axios';
import AuthContext from "../context/AuthProvider";
import { PayPalScriptProvider,PayPalButtons } from "@paypal/react-paypal-js";
import { incrementLocalCredits as increment } from '../functions/incrementLocalCredits.jsx';
import { Container } from 'react-bootstrap';
import Contact from '../Contact';
import "./AIBuilderCards.css";
import storeActivity from '../functions/storeActivity.jsx';
import CareerGeniusLogo from '../assets/CareerGeniusLogo.png'

import Card from '@mui/joy/Card';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Paper from '@mui/material/Paper';


export const AIBuilderCardsMobile = () => {

    const { auth,setAuth } = useContext(AuthContext);

    

  return (
    <div>


       {auth.loggedIn==1 ? 
      <div className="d-flex justify-content-center">
      <div style={{ maxWidth: '90%' }} >
        <Link to="/CoverLetterBuilder"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
        <Link to="/ResumeTools"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Resume AI Tools</Link>
        <Link to="/EmailBuilder"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Thank You Email AI</Link>
        <Link to="/InterviewPrep"   style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Interview Prep AI</Link> 
        {/* <Link to="/ResumeExperienceBuilder"   style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Resume Experience Optimizer AI</Link> */}
      </div>
      </div> : <div className="d-flex justify-content-center">
      <div style={{ maxWidth: '90%' }} >
        <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Cover Letter AI</Link>
        <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Resume AI</Link>
        <Link to="/loginform"  style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Thank You Email AI</Link>
        <Link to="/loginform"   style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Interview Prep AI</Link>
        {/* <Link to="/loginform"   style={{ margin: '10px' }} className="btn btn-success mr-2 same-size-btn">Social Post AI</Link> */}
      </div>
      </div> }

    </div>

  );

                };

export default AIBuilderCardsMobile