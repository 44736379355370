import React, { useState,useContext,useEffect } from 'react';
import axios from './api/axios';
import Cookies from 'js-cookie';
import { UserContext } from './context/UserContext';
import AuthContext from "./context/AuthProvider";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import storeActivity from './functions/storeActivity.jsx';

const LoginForm = () => {
    const { auth,setAuth } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [credits, setCredits] = useState(0);
    const [loggedIn, setLoggedIn] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [email, setEmail] = useState('');
    const [user_role_type, setUser_role_type] = useState('');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const {globalUser,setGlobalUser} = useContext(UserContext);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
      });
    

      useEffect(() => {         
        //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
        storeActivity( auth.sessionId       ,"Page Landing"     ,'LoginForm'             ,'Page'              ,'Landed on LoginForm page');
      }, []);

      const handleChange = (event) => {
        // Get the current input value
        const currentValue = event.target.value;
    
        // Use a regular expression to check for unwanted characters
        const unwantedCharacters = /[;'\"\s-]/g;
        if(unwantedCharacters.test(currentValue)) {
            // Stop the input
            event.preventDefault();
            alert("Characters ';' '\"' '\'' '-' are not allowed.");
        } else {
            const { name, value } = event.target;
            setFormData((prevState) => ({
              ...prevState,
              [name]: value,
            }));
        }
    };



  const handleSubmit = (event) => {
    event.preventDefault();
    

  setIsButtonDisabled(true); // disable the button
  axios.post('/login', formData, 
  {
    headers: {'Content-Type': 'application/x-www-form-urlencoded',},
    withCredentials: true // This sends the cookie with the request
  })
    .then((response) => {
      //console.log(response.status);
      // Redirect to homepage or show success message
      if(response.status ==200){
      const { user } = response.data;  
      sessionStorage.setItem('user', JSON.stringify(user));
      setUsername(response.data.username);
      setCredits(response.data.credits);
      setSessionId(response.data.sessionId);
      setEmail(response.data.email);
      setLoggedIn("1");
      setUser_role_type(response.data.user_role_type);
      localStorage.setItem('username', response.data.username);
      localStorage.setItem('credits', response.data.credits);
      localStorage.setItem('loggedIn', 1);
      localStorage.setItem('sessionId', response.data.sessionId);
      localStorage.setItem('email', response.data.email);
      localStorage.setItem('user_role_type', response.data.user_role_type);
      localStorage.setItem('isVerified', response.data.isVerified);
      setAuth({ username:response.data.username, credits:response.data.credits, loggedIn:1,sessionId:response.data.sessionId 
        ,email:response.data.email,user_role_type:response.data.user_role_type, isVerified:response.data.isVerified });
     
      window.location.replace("/");
      }
      if(response.status ==401){
        alert("Inccorect username and password combination.");
      }
      setIsButtonDisabled(false); // enable the button
    })
    .catch((error) => {
      //console.log(error);
      // Show error message
      if(error.response.status==401){
        alert("Inccorect username and password combination.");
      }
      setIsButtonDisabled(false); // enable the button
    });
};


  return (
    <div className="container">
        <br/>
        <h1>Login</h1>
        <br/>
  <form onSubmit={handleSubmit}>
    <div className="form-group row">
      <label htmlFor="username" className="col-sm-2 col-form-label">Username:</label>
      <div className="col-sm-10">
        <input
          type="text"
          id="username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
          className="form-control"
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="password" className="col-sm-2 col-form-label">Password:</label>
      <div className="col-sm-10">
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
          className="form-control"
        />
      </div>
    </div>
    <div className="form-group row">
      <div className="col-sm-2"></div>
      <div className="form-check col-sm-10">
        <input
          type="checkbox"
          id="rememberMe"
          name="rememberMe"
          checked={formData.rememberMe}
          onChange={handleChange}
          className="form-check-input"
          />
          <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
          </div>
          </div>
          <div className="form-group row">
          <div className="col-sm-2"></div>
          <div className="col-sm-10">
          {!isButtonDisabled ? <button type="submit" disabled={isButtonDisabled} className="btn btn-primary">Login</button> : <p>Loading...</p>}
          </div>
          </div>
          
            </form>
            <p>Not a member yet? <Link to="/signupform">Sign up</Link> </p>
            <p><Link to="/ForgotUsername">Forgot Username?</Link> </p>
            <p><Link to="/RequestResetPassword">Forgot Password?</Link> </p>
          </div>
  
  );
};

export default LoginForm;
