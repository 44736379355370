import React, { useState,useContext,useEffect } from 'react';
import axios from './api/axios';
import "./TermsAndConditions.css";
import AuthContext from "./context/AuthProvider";
import storeActivity from './functions/storeActivity.jsx';

const TermsAndConditions = () => {
  
  const { auth,setAuth } = useContext(AuthContext);

  useEffect(() => {         
    //              session             ,event_type         ,currentPage        ,object_type         ,specific_opject   
    storeActivity( auth.sessionId       ,"Page Landing"     ,'TermsAndConditions'             ,'Page'              ,'Landed on TermsAndConditions page');
  }, []);

  return (
  <div style={{ padding: '20px',}}>
    <h1 className="mx-auto text-center">TERMS AND CONDITIONS</h1>
    <div class="container">
    <div>
  <h1>Terms and Conditions for Subscription of Premium services</h1>
  <p>By subscribing to our website, you agree to the following terms and conditions:</p>
  <ul>
    <li><p>We reserve the right to enforce a limit to usage at any time.</p></li>
    <li><p>You may cancel your subscription at any time, but refunds will not be issued for any remaining subscription period.</p></li>
    <li><p>You are responsible for maintaining the confidentiality of your account and password.</p></li>
    <li><p>We reserve the right to modify these terms and conditions at any time without prior notice.</p></li>
    <li><p>You may not use our website for any illegal or unauthorized purpose.</p></li>
    <li><p>All content on our website is the property of our company and may not be used without permission.</p></li>
    <li><p>We are not responsible for any loss or damage resulting from your use of our website.</p></li>
    <li><p>By using our website, you agree to indemnify and hold us harmless from any claims, damages, or expenses arising from your use of our website.</p></li>
  </ul>
  <p>By participating in the program, you are indicating your acceptance to be bound by the terms and conditions of this Agreement.</p>
</div>
    </div>
    <div class="container">
    <div>
  <h1>Terms and Conditions for Resume and Cover Letter Sharing</h1>
  <ol>
    <li>
      <p>By checking the opt-in box and submitting your resume or cover letter through our platform, you agree to allow us to share your resume and cover letter with recruiters and companies that we believe may be a good fit for your qualifications and experience.</p>
    </li>
    <li>
      <p>You can opt-out of having your resume or cover letter shared at any time by contacting us or unchecking the opt-in box on the form.</p>
    </li>
    <li>
      <p>We cannot guarantee that your resume or cover letter will be viewed by any specific recruiter or company, or that you will be contacted for a job opportunity.</p>
    </li>
    <li>
      <p>We are not responsible for any errors or inaccuracies in your resume or cover letter, or for any actions taken by recruiters or companies that receive your resume or cover letter.</p>
    </li>
    <li>
      <p>We reserve the right to discontinue the resume and cover letter sharing service at any time, and will not be held liable for any damages or losses that may result from the discontinuation of this service.</p>
    </li>
    <li>
      <p>By using our platform and opting-in to have your resume and cover letter shared, you agree to these terms and conditions and understand that your resume and cover letter may be shared with recruiters and companies at our discretion.</p>
    </li>
    <li>
      <p>By opting-in, you confirm that you have read, understood and agree to the terms and conditions outlined in this document, and have read and understand our privacy policy.</p>
    </li>
    <li>
      <p>We shall not be liable for any direct, indirect, consequential, or any other damages, losses, or expenses arising in connection with the use of our platform, including the sharing of your resume and cover letter with recruiters and companies.</p>
    </li>
    <li>
      <p>We reserve the right to modify or update these terms and conditions at any time and will notify you of any changes. Your continued use of the platform will be deemed as acceptance of any changes made to these terms and conditions.</p>
    </li>
    <li>
      <p>These terms and conditions shall be governed by and construed in accordance with the laws of the country in which our platform operates and any disputes arising in relation to the use of this platform shall be subject to the jurisdiction of courts of that country.</p>
    </li>
  </ol>
  <p>By participating in the program, you are indicating your acceptance to be bound by the terms and conditions of this Agreement.</p>
</div>
    </div>



    <div class="container">
    <h1>Affiliate Program Terms and Conditions</h1>
    <ol>
      <li>
        <p>Introduction: The affiliate program (the "Program") is operated by CareerGenius.org (the "Company") and allows approved affiliates ("Affiliates") to promote the Company's products and services and earn commissions for any resulting sales.</p>
      </li>
      <li>
        <p>Eligibility: The Program is open to any individual or entity that meets the following criteria:</p>
        <ul>
          <li>Must be at least 18 years of age</li>
          <li>Must have a valid tax ID or social security number</li>
          <li>Must have a valid PayPal account for commission payments</li>
        </ul>
      </li>
      <li>
        <p>Affiliate Obligations: Affiliates are required to comply with the following rules and regulations:</p>
        <ul>
          <li>Affiliates must promote the Company's products and services in a legal and ethical manner</li>
          <li>Affiliates must not engage in any deceptive or misleading practices, including but not limited to making false or misleading statements about the Company's products or services</li>
          <li>Affiliates must not engage in any activities that are deemed to be spamming or otherwise in violation of the law</li>
          <li>Affiliates must not use any copyrighted materials without the Company's prior written consent</li>
        </ul>
      </li>
      <li>
        <p>Compensation: Affiliates will earn a commission for each sale made as a result of their promotions. Commission payments will be made on a monthly basis to the PayPal account provided by the Affiliate.</p>
        </li>
        <li>
        <p>Termination: The Company reserves the right to terminate an Affiliate's participation in the Program at any time and for any reason.</p>
      </li>
      <li>
        <p>Limitation of Liability: The Company will not be liable for any losses or damages suffered by an Affiliate as a result of their participation in the Program.</p>
      </li>
      <li>
        <p>Governing Law and Dispute Resolution: These terms and conditions will be governed by and construed in accordance with the laws of Indiana state law and any disputes arising out of or related to these terms and conditions will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</p>
      </li>
      <li>
        <p>Modification: The Company reserves the right to modify these terms and conditions at any time and without notice. Any changes will be effective immediately upon posting.</p>
      </li>
      <li>
        <p>Acceptance of Terms and Conditions: Participation in the Program constitutes acceptance of these terms and conditions.</p>
      </li>
      <li>
        <p>Contact Information: If you have any questions or concerns, please contact us at <span class="bold">careergeniusapp@gmail.com</span>.</p>
      </li>
    </ol>
    <p>By participating in the program, you are indicating your acceptance to be bound by the terms and conditions of this Agreement.</p>
    </div>

  </div>

  );
  };

export default TermsAndConditions; 
 