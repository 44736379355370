import React, { useState } from 'react';

const EducationForm = ({ addEducation, handleNext, handleBack }) => {
  const [educationItem, setEducationItem] = useState(
    [{
    school: '',
    degree: '',
    fieldOfStudy: '',
    startDate: '',
    endDate: '',
  },]);



  const addEducationItem = () => {
    setEducationItem((prevEducationItem) => [
      ...prevEducationItem,
      {
        school: '',
        degree: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: '',
      },
    ]);
  };

  const handleChange = (event,index) => {
    const { name, value } = event.target;

    setEducationItem((prevEducationItem) => {
        const newEducationItem = [...prevEducationItem];
        newEducationItem[index] = {
          ...newEducationItem[index],
          [name]: value,
        };    
        return newEducationItem;
      });
   // setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    addEducation(educationItem);
    handleNext();
  };
  

  return (
    <form onSubmit={handleSubmit}>
        {educationItem.map((edu, index) =>
(
    <div key={index}>
      <label htmlFor="school">School:</label>
      <input
        type="text"
        id="school"
        name="school"
        value={edu.school}
        onChange={(event) => handleChange(event, index)}
      />
      <br />
      <label htmlFor="degree">Degree:</label>
      <input
        type="text"
        id="degree"
        name="degree"
        value={edu.degree}
        onChange={(event) => handleChange(event, index)}
      />
      <br />
      <label htmlFor="fieldOfStudy">Field of Study:</label>
      <input
        type="text"
        id="fieldOfStudy"
        name="fieldOfStudy"
        value={edu.fieldOfStudy}
        onChange={(event) => handleChange(event, index)}
      />
      <br />
      <label htmlFor="startDate">Start Date:</label>
      <input
        type="text"
        id="startDate"
        name="startDate"
        value={edu.startDate}
        onChange={(event) => handleChange(event, index)}
      />
      <br />
      <label htmlFor="endDate">End Date:</label>
      <input
        type="text"
        id="endDate"
        name="endDate"
        value={edu.endDate}
        onChange={(event) => handleChange(event, index)}
      />
      <br />
      <button type="button" onClick={addEducationItem}>
      Add Education
    </button>
  </div>
      ))}
      <button type="button" onClick={handleBack}>
        Back
      </button>
      <button type="submit">Next</button>
    </form>
  );
};

export default EducationForm;
